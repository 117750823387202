// Import CSS
import 'aos/src/sass/aos.scss'
import styles from '../scss/app.scss'

// import style (>= Swiper 6.x)
import 'swiper/css/swiper.css'

//Import vue components
import Vuex from 'vuex'
import AOS from 'aos'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueVideoSection from 'vue-video-section'
import { FocusTrap } from 'focus-trap-vue'
import VueYoutube from 'vue-youtube'

// App main
const main = async () => {
	// Async load the vue module
	const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue')

	//Use
	Vue.use(VueAwesomeSwiper)
	Vue.use(Vuex)
	Vue.use(VueYoutube)

	//Component
	Vue.component('FocusTrap', FocusTrap)

	//Vuex config and mutations
	const store = new Vuex.Store({
		state: {
			scrollPosition: 0,
			isSlideInOpen: false,
		},
		mutations: {
			adjustScrollPosition(state, position) {
				state.scrollPosition = position
			},
			setSlideInState(state, value) {
				state.isSlideInOpen = value
			},
		},
	})

	// Create our vue instance
	// @ts-ignore
	const vm = new Vue({
		el: '#app',
		delimiters: ['${', '}'],
		store,
		components: {
			Instagramfeed: () =>
				import(
					/* webpackChunkName: "Instagramfeed" */ './components/Instagramfeed.vue'
				),
			SiteMenu: () =>
				import(
					/* webpackChunkName: "SiteMenu" */ './components/SiteMenu.vue'
				),
			SlideInOverlay: () =>
				import(
					/* webpackChunkName: "SlideInOverlay" */ './components/SlideInOverlay.vue'
				),
			EmbeddedVideo: () =>
				import(
					/* webpackChunkName: "EmbeddedVideo" */ './components/EmbeddedVideo.vue'
				),
			BlockTable: () =>
				import(
					/* webpackChunkName: "BlockTable" */ './components/BlockTable.vue'
				),
			VueVideoSection,
		},
		//mixins: [clickaway],
		data() {
			return {
				showBanner: true,
				mountVideo: false,

				swiperOptionTop: {
					loop: true,
					effect: 'fade',
					loopedSlides: 1, // looped slides should be the same
					spaceBetween: 10,
					pagination: {
						clickable: true,
						el: '.swiper-pagination',
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				},
				swiperOptionCaption: {
					loop: true,
					loopedSlides: 1, // looped slides should be the same
					spaceBetween: 10,
					effect: 'fade',
					fadeEffect: {
						crossFade: true,
					},
					centeredSlides: true,
					slidesPerView: 'auto',
					slideToClickedSlide: false,
					allowTouchMove: false,
				},
			}
		},
		props: {},
		methods: {
			handleScroll: function (event) {
				if (store.state.overlayOpen) {
					return
				}

				const app = document.getElementById('app')
				let scrollPosY = window.pageYOffset | document.body.scrollTop

				if (app) {
					if (scrollPosY > 35) {
						if (!app.classList.contains('scrolled')) {
							app.classList.add('scrolled')
						}
					} else if (scrollPosY <= 35) {
						app.classList.remove('scrolled')
					}

					store.commit('adjustScrollPosition', scrollPosY)
				}
			},
			openSlideIn() {
				store.commit('setSlideInState', true)
			},

			slideTo(event, ref) {
				let slider = this.$refs[ref].$swiper
				console.log(event), slider.slideTo(event.activeIndex)
			},
		},

		computed: {
			isSlideInOpen() {
				return store.state.isSlideInOpen
			},
		},
		created() {
			AOS.init({
				disable:
					navigator.userAgent.indexOf('Chrome') == -1 &&
					navigator.userAgent.indexOf('Firefox') == -1,
				easing: 'ease-in-out',
				delay: 100,
				duration: 300,
				once: true,
				startEvent: 'load',
				anchorPlacement: 'top-bottom',
			})

			window.addEventListener('scroll', this.handleScroll)
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll)
		},
		beforeMount: function () {},
		mounted() {
			this.$nextTick(function () {
				this.mountVideo = true

				window.addEventListener('load', () => {
					let player = this.$refs['header-background-video']

					if (player) {
						this.$refs['header-background-video']
							.playVideo()
							.then(() => {
								this.$nextTick(function () {
									this.showBanner = false
								})
							})
					}
				})
			})
		},
	})

	return vm
}

// Execute async function
main().then((vm) => {})

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept()
}
